import "./App.css";
import "./BingoWidget";
import BingoWidget from "./BingoWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCandyCane } from "@fortawesome/free-solid-svg-icons";

function App() {
  return (
    <>
      <h1>
        <FontAwesomeIcon className="candyCane" icon={faCandyCane} />
        HALLMARK BINGO
        <FontAwesomeIcon className="candyCane" icon={faCandyCane} />
      </h1>
      <div className="container">
        <BingoWidget placeholder="Box-1" id="1" />
        <BingoWidget placeholder="Box-2" id="2" />
        <BingoWidget placeholder="Box-3" id="3" />
        <BingoWidget placeholder="Box-4" id="4" />
        <BingoWidget placeholder="Box-5" id="5" />
        <BingoWidget placeholder="Box-6" id="6" />
        <BingoWidget placeholder="Box-7" id="7" />
        <BingoWidget placeholder="Box-8" id="8" />
        <BingoWidget placeholder="Box-9" id="9" />
        <BingoWidget placeholder="Box-10" id="10" />
        <BingoWidget placeholder="Box-11" id="11" />
        <BingoWidget placeholder="Box-12" id="12" />
        <BingoWidget placeholder="Free Space" id="13" />
        <BingoWidget placeholder="Box-14" id="14" />
        <BingoWidget placeholder="Box-15" id="15" />
        <BingoWidget placeholder="Box-16" id="16" />
        <BingoWidget placeholder="Box-17" id="17" />
        <BingoWidget placeholder="Box-18" id="18" />
        <BingoWidget placeholder="Box-19" id="19" />
        <BingoWidget placeholder="Box-20" id="20" />
        <BingoWidget placeholder="Box-21" id="21" />
        <BingoWidget placeholder="Box-22" id="22" />
        <BingoWidget placeholder="Box-23" id="23" />
        <BingoWidget placeholder="Box-24" id="24" />
        <BingoWidget placeholder="Box-25" id="25" />
      </div>
    </>
  );
}

export default App;
