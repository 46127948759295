import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const BingoWidget = (props) => {
  const { placeholder, id } = props;
  const [cardText, setCardText] = useState(placeholder);
  const updateCard = (e) => {
    console.log(e);
    const newText = prompt("Enter New Card");
    setCardText(newText);
  };
  const updateBingo = (e) => {
    const box = document.getElementById(id);
    box.classList.toggle("active");
  };
  return (
    <>
      <div className="box" id={id}>
        <div className="icons">
          <FontAwesomeIcon icon={faCheck} onClick={updateBingo} />
          <FontAwesomeIcon icon={faEdit} onClick={updateCard} />
        </div>
        <p>{cardText}</p>
      </div>
    </>
  );
};

export default BingoWidget;
